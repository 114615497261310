import {
  favoriteDelete,
  favoriteDeferred,
  favoriteAddToFolder,
  favoriteCreateFolderAndAdd,
  favoriteCreateFolderAndTransfer,
  favoriteTransferToFolder,
} from '~/utils/toggleFavoriteState';

export function useFavorite() {
  const app = useNuxtApp();

  const onFavoriteDelete = (favorite, article, sendGTM = true, cb = () => {}) => {
    favoriteDelete(favorite, article, app, sendGTM, cb);
  };

  const onFavoriteCreateFolderAndAdd = (nameFolder, article, sendGTM = true, cb = () => {}) => {
    favoriteCreateFolderAndAdd(nameFolder, article, app, sendGTM, cb);
  };

  const onFavoriteCreateFolderAndTransfer = (
    nameFolder,
    favorite,
    article,
    _sendGTM,
    cb = () => {},
  ) => {
    favoriteCreateFolderAndTransfer(nameFolder, favorite, article, undefined, app, cb);
  };

  const onFavoriteTransferToFolder = (favorite, folder, article, _sendGTM, cb = () => {}) => {
    favoriteTransferToFolder(favorite, folder, article, undefined, app, cb);
  };

  const onFavoriteAddToFolder = (folder, article, sendGTM = true) => {
    favoriteAddToFolder(folder, article, sendGTM, app);
  };

  const onFavoriteDeferred = (deferredAction) => {
    favoriteDeferred(deferredAction, app);
  };

  return {
    onFavoriteDelete,
    onFavoriteCreateFolderAndAdd,
    onFavoriteCreateFolderAndTransfer,
    onFavoriteTransferToFolder,
    onFavoriteAddToFolder,
    onFavoriteDeferred,
  };
}
