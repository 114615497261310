import { getDateText } from '@devhacker/shared/utils/date';
import { computed } from 'vue';
import { ADS_EXCEPTIONS, ADS_LABELS } from '~/constants/ads-labels';
import { useIndexStore } from '~/store';
import { useArticlesStore } from '~/store/articles';
import { useFoldersStore } from '~/store/folders';
import { getEditLinkHref, getRelativeLink, isHomeLink } from '~/utils';

export function useArticleProps(article: IArticle) {
  const articleStore = useArticlesStore();
  const indexStore = useIndexStore();
  const folderStore = useFoldersStore();

  const internalUrl = computed(() => {
    return article.teaserUrl ? false : isHomeLink(article.url);
  });

  const url = computed(() => {
    return article.teaserUrl || (internalUrl.value ? getRelativeLink(article.url) : article.url);
  });

  const articleProps = computed(() => {
    const {
      title,
      id,
      categories,
      author,
      img_alt,
      isAdvertising,
      isPromo,
      commentsEnabled,
      img,
      defaultImg,
      teaserUrl,
      type,
      advertLabel,
      adsToken,
      tags,
    } = article;

    return {
      id,
      categories,
      isPromo,
      isAdvertising: isAdvertising && !ADS_EXCEPTIONS.includes(advertLabel),
      isTeaser: !!teaserUrl,
      commentsEnabled,
      internalUrl: internalUrl.value,
      url: url.value,
      title,
      images: article.image?.sizes || img,
      imgAlt: img_alt || article.image?.alt || article.title,
      tags,
      defaultImages: defaultImg,
      authorText: author?.name || '',
      authorUrl: author?.profile || '',
      inFavorites: articleStore.getArticleFavoriteId(id) > 0,
      dateText: getDateText(article.date),
      commentsCount: articleStore.getCommentsCount(id),
      isAuthenticatedInWordpress: indexStore.isAuthenticatedInWordpress,
      editLinkHref: getEditLinkHref(id),
      viewsCount: articleStore.getViewsCount(id),
      user: indexStore.user,
      folders: folderStore.$state,
      favorite: articleStore.getArticleFavorite(id),
      favoriteMenuOffsets: {
        top: 48, // учитывать fixed шапку
      },
      type,
      advertLabel,
      partnerText: ADS_LABELS[advertLabel],
      adsToken,
    };
  });

  return articleProps.value;
}
